import React, { useState, useContext, useEffect, useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { UserContext } from '../../context/UserContext';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Container from '@mui/material/Container';
import { Box, CircularProgress, Typography, Button, Avatar, Stack, Link } from '@mui/material';
import { editUserLogin } from '../../Service/UserService';
import EditUser from '../ManageEditUserLogin/EditUser';


function InfoAccount(props){
    const [loading, setLoading] = useState(false);
    const {user,isDialogChangePasswordOpen, setIsDialogChangePasswordOpen} = useContext(UserContext);
    const [inputUser, setInputUser] = useState({})
    const [openModalEditUser, setOpenModalEditUser] = useState(false);
    const handleClickChangePassword = () => {
        if(!isDialogChangePasswordOpen){
            setIsDialogChangePasswordOpen(true);
        }
    }
    useEffect(() => {
        if(!!user){
            setInputUser({
                userName: user.userName,
                userEmail: user.userEmail,
                userFullName: user.userFullName
            })
        }
    },[user])
    return (
        <Container maxWidth="sm" sx={{ mt: 3 }} style={{border: '1px solid #fff', borderRadius: '10px', height: '340px', padding: 0, boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)'}}>
                <Box sx={{ height: 'auto' }}>
                    {loading ?
                    <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 1 }}>
                                Đang tải dữ liệu, hãy chờ một chút...
                            </Typography>
                    </Box>
                    :
                    <Box  spacing={1}>
                        <Box xs={8}  sx={{pt:"0px !important ", mt:3, ml:4}}>
                            <Typography variant="h4" color="primary" gutterBottom >
                                Thông tin cá nhân
                            </Typography>

                            {/* Profile Content */}
                            <Stack direction="row" spacing={2} sx={{ml:2}}>
                                {/* Profile Picture */}
                                <Box textAlign="center">
                                <Avatar sx={{ width: 140, height: 140, backgroundColor: 'grey.400', mr:2 }}>
                                    <PermIdentityIcon sx={{width: 70, height: 70}} />
                                </Avatar>
                                </Box>

                                {/* Profile Details */}
                                <Box>
                                    <Typography variant='h6' sx={{mb:1}}>
                                        <strong>Họ và tên:</strong> {user.userFullName}
                                    </Typography>
                                    <Typography variant="body1" sx={{mb:1}}>
                                        <strong>Tên đăng nhập:</strong> {user.userName}
                                    </Typography>
                                    
                                    <Typography variant="body1" sx={{mb:1}}>
                                        <strong>Chức vụ:</strong> {user.positionName}
                                    </Typography>
                                    <Typography variant="body1" sx={{mb:1}}>
                                        <strong>Email:</strong> {user.userEmail || 'chưa có'}
                                    </Typography>
                                </Box>
                                
                            </Stack>
                            <Stack direction='row' spacing={3} sx={{ml:2, mt:5, mr:6}}>
                            <Box sx={{display: 'flex', mt: 1, width: '100%', justifyContent:'space-around'}}>
                                    <Button variant="contained" onClick={() => {setOpenModalEditUser(true)}} >Chỉnh sửa thông tin</Button>
                                    <Button variant="contained" color="warning" onClick={() => handleClickChangePassword()}>Đổi mật khẩu</Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                    
                    }
                </Box>
                <EditUser openModalEditUser = {openModalEditUser} setOpenModalEditUser={setOpenModalEditUser} user={inputUser} isAdmin={false}></EditUser>
        </Container>
        
    )
}
export default InfoAccount