import axios from "axios";

import {backendURL, createConfig} from "../Service/BaseService"

const getReportPatient = async (dateSelectedReport) => {
    const config = createConfig();
    return await axios.get(`${backendURL}/api/Admin/GetReportPatient?dateStart=${dateSelectedReport.dateStart}&dateEnd=${dateSelectedReport.dateEnd}`, config)
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        return error.response.status
    })
}
const getGeneralStatistics = async () => {
    const config = createConfig();
    return await axios.get(`${backendURL}/api/Admin/GeneralStatistics`, config)
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        return error.response.status
    })
}

const getListUser = async () => {
    const config = createConfig();
    return await axios.get(`${backendURL}/api/UserAccount/GetUserAccounts`, config)
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        return error.response.status
    })
}

export {
    getReportPatient, getListUser, getGeneralStatistics
}