// SignalModel.js

// Danh sách các loại tín hiệu (Signal Types)
export const SignalType = {
    MEDICAL_REGISTER: "MEDICAL_REGISTER",
    NOTIFICATION: "NOTIFICATION",
    CHAT_MESSAGE: "CHAT_MESSAGE",
    PATIENT_UPDATE: "PATIENT_UPDATE",
    PATIENT_CANCEL:"PATIENT_CANCEL"
    // Thêm các loại tín hiệu khác nếu cần
};

// Danh sách các nhóm tín hiệu (Signal Groups - là danh sách chức vụ)
export const SignalGroup = {
    ADMIN: "Admin",
    DOCTOR: "Doctor",
    NURSE: "Nursing",
    // RECEPTION: "Reception",
    // Thêm các nhóm khác nếu cần
};

// Mẫu tín hiệu (Signal Data Model)
export class SignalData {
    constructor(type, group, data) {
        this.type = type; // Loại tín hiệu (SignalType)
        this.group = group; // Nhóm nhận tín hiệu (SignalGroup)
        this.data = data; // Dữ liệu gửi kèm
    }
}
