const backendURL = 'https://apiknkpt.dvtien.id.vn';
// const backendURL = 'https://localhost:5002';

const createConfig = () => {
  const token = localStorage.getItem("jwt");
  // Thiết lập tiêu đề "Authorization" trong yêu cầu Axios
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return config;
}

export {
    backendURL, createConfig
}