import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//context
import { UserContext } from '../../context/UserContext';
//lodash
import _, { constant } from "lodash";
//modal
import CompleteExamination from '../ManageCompleteExamining/CompleteExamination';
import AlertProcessingBackdrop from '../ManageAlertProcessingBackdrop/AlertProcessingBackdrop';
//components
import PreviewLastExamining from './PreviewLastExamining';
//mui theme
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, IconButton, TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import TextareaAutosize from 'react-textarea-autosize';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
//mui icon
import SearchIcon from '@mui/icons-material/Search';
import PinIcon from '@mui/icons-material/Pin';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import Home from '@mui/icons-material/Home';
import { GiBodyHeight } from "react-icons/gi";
import { GiWeightScale } from "react-icons/gi";
import SendIcon from '@mui/icons-material/Send';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TbRulerMeasure } from "react-icons/tb";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//moment
import moment from 'moment';
//scss
import './SCSS/DoctorExamining.scss';
//real-time
import {startSignalRConnection, onReceiveMessage, sendSignal} from '../../Service/SignalService';
//api
import { 
    createAddPredecessor,
    getMedicalDetailPatient,
    getRegistersByDateNow, getMedicalBook, getUpdatePredecessor, getUpdateMedicalBook,
    getUpdateCategoryPre, updateOneCategoryPre,
    updateMedicalState,
    deleteMedicalBook
} from '../../Service/MedicalService';
import { toast } from 'react-toastify';
import ScreenContent from '../../config/screenContent';
import { Details } from '@mui/icons-material';
import { SignalGroup, SignalType } from '../../models/SignalModel';

function MainDoctorExamining() {
    const dataPantientsReadyExaminingDefault = {
        stt: '',
        id: '',
        patientsId: '',
        patientCode: '',
        patientsName: '',
        patientsDOB: '',
        patientsMonthsOld: '',
        patientsGender: '',
        patientsHeight: '',
        patientsWeight: '',
        patientsHeadCircumference: '',
        patientsPhone: '',
        patientsAddress: '',
        vaccination:'',
        stateRegisterInitial:'',
        isContinueExam:''
    }
    const { user, loading, alertVisible, confirmAlert, resetAlert, isLogOutClick, isDialogChangePasswordOpen } = useContext(UserContext);
    const history = useHistory();
    const [isFormDirty, setIsFormDirty] = useState(false);

    // Màn hình nội dung
    const [currentScreen, setCurrentScreen] = useState(ScreenContent.NoScreen)

    // ========== DATA chính của bệnh nhân ===============
    //state set thông tin bệnh nhân được chọn ban đầu 
    const [dataPantientsReadyExamining, setDataPantientsReadyExamining] = useState(dataPantientsReadyExaminingDefault);
    //state lưu thông tin chi tiết trạng thái khám
    const [detailPatient, setDetailPatient] = useState();
    //state danh sách nội dung chi tiết của sổ khám bệnh
    const [healthRecordsContents, setHealthRecordsContents] = useState([]);
    const [dataUpdatePres, setDataUpdatePres] = useState([]);
    const [selectUpdatePre, setSelectUpdatePre] = useState();
    
    
    // state mở thông tin patient
    const [isShowInfoPatient, setIsShowInfoPatient] = useState(false);
    // state đang khám
    const [isExamining, setIsExamining] = useState(false);
    // state mở khám khi load trang có state = 1
    const [isLoadOpenExamining, setIsLoadOpenExamining] = useState(false);
    // state đang tải dữ liệu
    const [isLoadingData, setIsLoadingData] = useState(false)


    //state đóng mở cây thư mục khám của sổ khám bệnh
    const [isOpenCollapseHealthRecords, setOpenCollapseHealthRecords] = useState(false);

    //state đóng mở chi tiết nội dung của sổ khám bệnh
    const [isOpenCollapseHealthRecordsItem, setOpenCollapseHealthRecordsItem] = useState([]);

    //state chọn danh mục khám ban đầu
    const [categorySelectedExamining, setCategorySelectedExamining] = useState({});

    //state chọn danh mục gồm nội dung ban đầu
    const [contentCategorySelectedExamining, setContentCategorySelectedExamining] = useState([]);

    
    // Data sổ khám được chọn
    const [currentHealthRecordExamining, setCurrentHealthRecordExamining] = useState();
    
    //tiền căn show ở thông tin bệnh nhân
    const [previewPredecessor, setPreviewPredecessor] = useState();

    //state các câu hỏi được bác sĩ khám tích hoặc gõ vào
    const [dataExaminingForConclusion , setDataExaminingForConclusion ] = useState({});

    //state quản lý modal kết thúc khám
    const [openModalCompleteExamining, setOpenModalCompleteExamining] = useState(false);

    const [loadingPrevDataExamining, setLoadingPrevDataExamining] = useState(false);
    const [loadingGetRegisterByDateNow, setLoadingGetRegisterByDateNow] = useState(true);
    const [loadingPatient, setLoadingPatient] = useState(true);
    const [loadingInfoPatient, setLoadingInfoPatient] = useState(true);
    const [loadingCategoryExamining, setLoadingCategoryExamining] = useState(false);

    const [listDataPatientsRegister, setListDataPatientsRegister] = useState([]); 
    const [searchPatientsQuery, setSearchPatientsQuery] = useState("");
    
    
    const [activeChip, setActiveChip] = useState({chipOrder: 0, chipLabel: 'BN chờ khám'});

    // Danh sách bệnh nhân
    const [listPantientChipState, setListPantientChipState] = useState(
        [
            { chipLabel: 'BN chờ khám', chipContent: 0, listPatient:[] },
            { chipLabel: 'BN đã khám', chipContent: 0,  listPatient:[]  }
        ]
    )
    const [openAlertProcessingBackdrop, setOpenAlertProcessingBackdrop] = useState(false);
    const [listDataPatientsRegisterSort, setListDataPatientsRegisterSort] = useState(listPantientChipState[activeChip.chipOrder]?.listPatient || []);
    
    const handleSelectedChip = (chipIndex, chipLabel) => {
        setActiveChip({chipOrder: chipIndex, chipLabel: chipLabel});
        
    };
    // xử lý khi search patient
    const handleSearchPatient = (value) => {
        setSearchPatientsQuery(value);

        const currentList = listPantientChipState[activeChip.chipOrder]?.listPatient || [];
        
        if (value === '') {
            if (listDataPatientsRegisterSort.length !== currentList.length) {
                setListDataPatientsRegisterSort(currentList);
            }
        } else {
            const filteredList = searchPatients(currentList, value);
            setListDataPatientsRegisterSort(filteredList)
        }
    };
    // tìm bệnh nhân
    const searchPatients = (patients, searchTerm) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return patients.filter(patientObj => {
            const { patientCode = '', fullName = '', phoneMother = '', phoneFather = '' } = patientObj.patient;
            return patientCode.toLowerCase().includes(lowerCaseSearchTerm) ||
                fullName.toLowerCase().includes(lowerCaseSearchTerm) ||
                phoneMother.toLowerCase().includes(lowerCaseSearchTerm) ||
                phoneFather.toLowerCase().includes(lowerCaseSearchTerm);
        });
    };

    //lấy danh sách đăng ký khám trong ngày
    const handleGetRegistersByDateNow = async (check) => {
        // await new Promise(resolve => setTimeout(resolve, 1 * 1000));
        // setListDataPatientsRegister([]);
        const response = await getRegistersByDateNow();
        if(response !== 400){
            let responseListPatient = response.list;
            //lọc bệnh nhân được phép khám và sửa
            const filterPatientAllowed = responseListPatient
                .filter(item => 
                    (item.state === 0 || item.state === 3 || ((item.state === 1 || item.state === 2) && item.userIdDoctor === user.userId)))
            // console.log(filterPatientAllowed, user.userId)
            setListDataPatientsRegister(filterPatientAllowed);

            if(check){
                //Tìm bệnh nhân đang khám
                const findPantientIsExam = filterPatientAllowed.find(item => item.state === 1 && item.userIdDoctor == user.userId);
                if(findPantientIsExam){
                    await handleApplyPatientData(findPantientIsExam)
                    setIsExamining(true)
                    
                }
            }
            setLoadingPatient(false);
            setLoadingInfoPatient(false);
            return filterPatientAllowed;
        }

        setLoadingPatient(false);
        setLoadingInfoPatient(false);
        
    }
    // Hàm tiện ích để đặt lại các trạng thái
    const resetExaminingState = () => {
        setDataPantientsReadyExamining(dataPantientsReadyExaminingDefault);
        setSelectUpdatePre();
        setDataUpdatePres([]);
        setHealthRecordsContents([]);
        setCategorySelectedExamining({});
        setContentCategorySelectedExamining([]);
        setCurrentHealthRecordExamining();
        setDataExaminingForConclusion({});
        setPreviewPredecessor();
        setDetailPatient();
        setOpenCollapseHealthRecords(false);
        setOpenCollapseHealthRecordsItem([]);
    };

    // Hàm reload đăng ký khám theo ngày hiện tại
    const handleReloadGetRegistersByDateNow = async () => {
        if(!isLoadingData){
            await handleGetRegistersByDateNow();
            toast.success(`Tải dữ liệu thành công`, {toastId: 'success1', position: toast.POSITION.BOTTOM_RIGHT});
            setIsLoadingData(false);
        }
        
    };

    // Hoàn tất khám bệnh cho bệnh nhân
    const handleCompleteExaminingForPantient = () => {
        setLoadingGetRegisterByDateNow(true);
        setCurrentScreen(ScreenContent.NoScreen);
        setIsExamining(false);
        toast.success(`Đã hoàn tất khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}`, { toastId: 'success1' });
        resetExaminingState();
        setLoadingPatient(false);
    };

    // Hủy khám bệnh
    const handleCancelExamining = async () => {
        if(dataUpdatePres.length > 0){
            toast.error("Vui lòng hoàn tất chỉnh sửa tiền căn", {
                position: toast.POSITION.BOTTOM_CENTER
              });
            return;
        }
        setLoadingPatient(true);
        setLoadingInfoPatient(true);
        const response = await updateMedicalState(dataPantientsReadyExamining.id, dataPantientsReadyExamining.stateRegisterInitial);
        if (response.status === 200) {
            setListDataPatientsRegister(prevState =>
                prevState.map(item =>
                    item.id === dataPantientsReadyExamining.id
                        ? { ...item, state: dataPantientsReadyExamining.stateRegisterInitial }
                        : item
                )
            );
            if(dataPantientsReadyExamining.stateRegisterInitial != 2){
                sendSignal(SignalType.MEDICAL_REGISTER, SignalGroup.DOCTOR, { patientId: dataPantientsReadyExamining.patientsId, userName: user.userName, msg:''});
                sendSignal(SignalType.MEDICAL_REGISTER, SignalGroup.NURSE, { patientId: dataPantientsReadyExamining.patientsId, state:dataPantientsReadyExamining.stateRegisterInitial, userName: user.userName, msg:''});
            }
            toast.success(`Đã hủy khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}`, { toastId: 'success2' });
            setIsExamining(false);
            setCurrentScreen(ScreenContent.NoScreen);
            resetExaminingState();
        } else if (response.status === 400) {
            toast.error('Bạn không phải bác sĩ khám hôm nay, không thể dùng chức năng này', { toastId: 'error1' });
        }
        setLoadingPatient(false);
        setLoadingInfoPatient(false);
    };

    // Xử lý khi đóng Info Patient
    const hanldeCloseInfoPatient = () => {
        if (!isExamining) {
            setIsShowInfoPatient(false);
            resetExaminingState();
        }
    };

    //tìm danh mục khám gồm tiền căn, sổ sức khỏe, dinh dưỡng...
    const handleGetMedicalDetailPatient = async (id) => {
        const responseDataExamining = await getMedicalDetailPatient(id);
        setDetailPatient(responseDataExamining);
        if(responseDataExamining.medicalBook != null){
            setHealthRecordsContents((prevRecords) => {
                const updatedRecords = prevRecords.map(record =>
                    record.medicalRegisterId === responseDataExamining.medicalBook.medicalRegisterId ? responseDataExamining.medicalBook : record
                );
                updatedRecords.medicalRegisterId = id;
                const exists = prevRecords.some(record => record.medicalRegisterId === responseDataExamining.medicalBook.medicalRegisterId);
                return exists ? updatedRecords : [...prevRecords, responseDataExamining.medicalBook];
            });
        }

        const findNewCategoryPreIsFalse = responseDataExamining.categoryPres.filter(item => item.newCategoryPre === false);
        const previewDataPredecessor = findNewCategoryPreIsFalse.flatMap(item => 
            item.categoryContents.flatMap(content => 
                content.categoryContentQuestions.map(question => ({
                    categoryContentQuestionOrder: question.categoryContentQuestionOrder,
                    categoryContentQuestionName: question.categoryContentQuestionName,
                    categoryContentQuestionType: question.categoryContentQuestionType,
                    categoryContentAnswer: question.categoryContentAnswer,
                    categoryContentNote: question.categoryContentNote,
                    categoryContentDefauls: question.categoryContentDefauls,
                    categoryOrder: item.categoryOrder // Thêm categoryOrder từ cấp trên
                }))
            )
        )
        // console.log(previewDataPredecessor)
        setPreviewPredecessor(previewDataPredecessor);
        return responseDataExamining;
    }
    
    const handleApplyPatientData = async (dataPantientItem) => {
        setIsShowInfoPatient(true);
        setLoadingInfoPatient(true);
        if(dataPantientItem.state !== 1){
            setLoadingPrevDataExamining(true);
        }
        setPreviewPredecessor();
        const responseDataExamining = await handleGetMedicalDetailPatient(dataPantientItem.id);
        setDataPantientsReadyExamining({
            stt: dataPantientItem.order,
            id: dataPantientItem.id,
            status: dataPantientItem.state,
            patientsId: dataPantientItem.patient.patientId,
            patientCode: dataPantientItem.patient.patientCode,
            patientsName: dataPantientItem.patient.fullName,
            patientsDOB: dataPantientItem.patient.dayOfBirth,
            patientsMonthsOld: dataPantientItem.patient.monthsOfAge,
            patientsGender: dataPantientItem.patient.gender,
            patientsHeight: dataPantientItem.height,
            patientsWeight: dataPantientItem.weight,
            patientsHeadCircumference: dataPantientItem.headCircumference,
            patientsPhone: dataPantientItem.patient.phoneMother ?? dataPantientItem.patient.phoneFather,
            patientsAddress: dataPantientItem.patient.fullAddress,
            vaccination: dataPantientItem.vaccination === true,
            stateRegisterInitial: getStateRegisterInitial(dataPantientItem.state, responseDataExamining),
            isContinueExam: false,
        });     
        if(dataPantientItem.state === 1){
            setIsLoadOpenExamining(true);
        }else{
            setLoadingPrevDataExamining(false);
            setCurrentScreen(ScreenContent.ClickPatient);
        }  
        setLoadingInfoPatient(false);
        
    }
    const getStateRegisterInitial = (state, responseDataExamining) => {
        if(state != 1) {
            return state;
        }
        let stateOld = 2;
        // console.log(detailPatient)
        const checkNullMedicalBookId = responseDataExamining.healthRecords.some(healthRecordsItem => healthRecordsItem.medicalBookId === null);
        if(checkNullMedicalBookId){
            if(responseDataExamining.healthRecords.length >= 2){
                stateOld = 3;
            }else{
                stateOld = 0;
            }
        }
        return stateOld;
    }

    //nhấn chọn bệnh nhân để load vào ô thông tin bn
    const handleSelectPantientExamining = async (dataPantientItem) => {
        if(isExamining){
            // alert();
            toast.error(`Bạn đang khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}`, {toastId: 'error1'});
        }else{
            await handleApplyPatientData(dataPantientItem);
        }
    }
    
    // Xử lý khi nhấn bắt đầu khám
    const handleStartExaminingButtonClick = () => {
        if(isExamining && dataPantientsReadyExamining.status === 1){
            alert(`Bạn đang trong quá trình khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}, vui lòng kết thúc đợt khám hiện tại để bắt đầu đợt khám mới!`);
        }else{
            handleBeginExaminingForPantient();
        }
    }

    //bắt đầu khám bệnh cho bệnh nhân
    const handleBeginExaminingForPantient = async () => {
        setLoadingPatient(true);
        try{
            const response = await updateMedicalState(dataPantientsReadyExamining.id, 1)
            if(response.status === 200){
                
                setDataPantientsReadyExamining(prev => ({ ...prev, status: 1 }));
                // update state = 1
                setListDataPatientsRegister(prevState => {
                    const updatedList = prevState.map(item =>
                        item.id === dataPantientsReadyExamining.id
                            ? { ...item, state: 1 }
                            : item
                    );
                    return updatedList;
                });
                setDetailPatient(prevState => {
                    const updatedHealthRecords = [...prevState.healthRecords]; 
                    updatedHealthRecords[0] = { ...updatedHealthRecords[0], state: 1 }; 
                    return {
                        ...prevState, 
                        healthRecords: updatedHealthRecords
                    };
                });
                
                sendSignal(SignalType.MEDICAL_REGISTER, SignalGroup.DOCTOR, { patientId: dataPantientsReadyExamining.patientsId, userName: user.userName, msg:''});
                sendSignal(SignalType.MEDICAL_REGISTER, SignalGroup.NURSE, { patientId: dataPantientsReadyExamining.patientsId, state:1, userName: user.userName, msg:''});
                if(dataPantientsReadyExamining.stateRegisterInitial !== 2){
    
                    if(dataPantientsReadyExamining.stateRegisterInitial === 3){
                        // Tái khám
                    }
                    await getApiMedicalBook(dataPantientsReadyExamining.id);
                    toast.success(`Đang khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}`, {toastId: 'success1'});
                }
                else{
                    
                    const responseGetUpdateMedicalBook = await getUpdateMedicalBook(detailPatient.healthRecords[0].medicalBookId);
                    if(responseGetUpdateMedicalBook.status === 200){
                        setHealthRecordsContents((record) =>
                            record.map((medicalBook) => 
                                medicalBook.medicalRegisterId === responseGetUpdateMedicalBook.data.medicalRegisterId
                                ?{
                                    ...medicalBook,
                                    ...responseGetUpdateMedicalBook.data
                                }:
                                medicalBook
                            )
                            
                        )
                    }
                    toast.success(`Đã mở khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}`, {toastId: 'success1'});
                }
                // Chuyển giao diện bắt đầu khám
                handleSelectTitleHealthRecords()
                setIsExamining(true)
            }
            else if(response.status === 400){
                toast.error(response.data, {toastId: 'error1'});
            }
        }catch (e){
            toast.error(e, {toastId: 'error1'});
        }
        
        setLoadingPatient(false);
    }
    // xử lý khi chọn tiền căn 
    const handleSelectCategoryClick = (predecessorItem) => {
        if(isEditPredecessor(predecessorItem.categoryId)){
            handleEditDataPredecessor(predecessorItem.categoryId)
        }else{
            setLoadingCategoryExamining(true);
            setOpenCollapseHealthRecords(false)
            setCategorySelectedExamining(predecessorItem);
            setCurrentScreen(ScreenContent.ClickPredecessor);
            setLoadingCategoryExamining(false);
        }
        
    }
    // Xử lý khi nhấn icon sổ sức khỏe
    const handleSelectHealthRecords = () => {
        if(!isOpenCollapseHealthRecords){
            setCurrentScreen(ScreenContent.ClickListBook);
        }else{
            setCurrentScreen(ScreenContent.NoScreen);
        }
        setOpenCollapseHealthRecords(!isOpenCollapseHealthRecords)
    }
    // Xử lý khi nhấn title sổ sức khỏe
    const handleSelectTitleHealthRecords = () => {
        setCurrentScreen(ScreenContent.ClickListBook)
        setOpenCollapseHealthRecords(true)
    }
    // Xử lý khi nhấn 1 sổ khám
    const handleSelectHealthRecordsItem = async (healthRecordsIndex) =>  {
        setCurrentScreen(ScreenContent.LoadScreen)
        var medicalRegister = detailPatient.healthRecords[healthRecordsIndex];
        var responseMedicalBook = await getApiMedicalBook(medicalRegister.medicalRegisterId, true);
        responseMedicalBook.examinationName = medicalRegister.examinationName
        responseMedicalBook.doctorFullName = medicalRegister.doctorFullName
        responseMedicalBook.timeUpdateState = medicalRegister.timeUpdateState
        responseMedicalBook.timeRegister = medicalRegister.timeRegister
        setCurrentHealthRecordExamining(responseMedicalBook)
        
        setCurrentScreen(ScreenContent.ClickOneBook)
    }
    // Gọi api lấy medicalBook nếu check = true thì kiểm tra tồn tại
    const getApiMedicalBook = async (medicalRegisterId, check) => {
        let responseMedicalBook;
        let index = -1;
        if(check){
            index = healthRecordsContents.findIndex(record => record.medicalRegisterId === medicalRegisterId);
            
            responseMedicalBook = index!= -1 ? healthRecordsContents[index] : await getMedicalBook(medicalRegisterId);
        }else{
            responseMedicalBook = await getMedicalBook(medicalRegisterId);
        }
        if (index === -1) {
            setHealthRecordsContents(prevRecords => {
                const exists = prevRecords.some(record => record.medicalRegisterId === responseMedicalBook.medicalRegisterId);
                if (exists) {
                    // Cập nhật record
                    return prevRecords.map(record =>
                        record.medicalRegisterId === responseMedicalBook.medicalRegisterId
                            ? { ...record, ...responseMedicalBook }
                            : record
                    );
                } else {
                    // Thêm mới record
                    return [...prevRecords, responseMedicalBook];
                }
            });
        }
        return { ...responseMedicalBook };
    }

    // Xử lý khi nhấn kết thúc khám
    const handleCompleteExaminingButtonClick = async () => {
        if(dataUpdatePres.length > 0){
            toast.error("Vui lòng hoàn tất chỉnh sửa tiền căn", {
                position: toast.POSITION.BOTTOM_CENTER
              });
        }else{
            const medicalRegisterId = dataPantientsReadyExamining.id;
            const categoriesPres = detailPatient.newPredecessor ? filterDataCompleteExam(detailPatient.categoryPres, false): [];
            let categoriesBook = await getApiMedicalBook(medicalRegisterId, true);
            const categoriesBookFilter = categoriesBook != null ? filterDataCompleteExam(categoriesBook.categories, true).filter((category) => category.categoryContents.length > 0): [];
            const categories = [...categoriesPres, ...categoriesBookFilter];
            categoriesBook.categories = categories;
            categoriesBook.patientId = dataPantientsReadyExamining.patientsId;
            categoriesBook.isVaccination = dataPantientsReadyExamining.vaccination;
            // console.log(categoriesBook)
            setDataExaminingForConclusion(categoriesBook);
            setOpenModalCompleteExamining(true);
        }
    }
    //Lọc dữ liệu để kết thúc khám
    const filterDataCompleteExam = (categories, isBook) => {
        return categories.map((category) => ({
                ...category,
                isMedicalBook: isBook,
                categoryContents: category.categoryContents
                    .map((content) => ({
                        ...content,
                        categoryContentQuestions: content.categoryContentQuestions.filter(
                            (question) =>
                                question.categoryContentAnswer === true ||
                                (question.categoryContentNote != null &&
                                question.categoryContentNote.trim() !== "")
                        ),
                    }))
                    .filter((content) => content.categoryContentQuestions.length > 0),
            }))
    };
    //========================================================= XỬ LÝ NHẬP DỮ LIỆU ============================================================
    const typingRef = useRef(null);
    // ================ Nhập Tiền căn mới
    // Xử lý chọn checkbox của new tiền căn
    const handleAnswerCheckNewPres = (contentsIndex, questionIndex, contentAnswer) => {
        setDetailPatient((details) => ({
            ...details,
            categoryPres: details.categoryPres.map((cate) =>
                cate.categoryOrder === categorySelectedExamining.categoryOrder
                ?{
                    ...cate,
                    categoryContents: cate.categoryContents.map((content, cIndex) =>
                        cIndex === contentsIndex
                            ? {
                                  ...content,
                                  categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                      qIndex === questionIndex
                                          ? { ...question, categoryContentAnswer: !contentAnswer }
                                          : question
                                  ),
                              }
                            : content
                    ),
                }
                :
                cate
            )
        }))
        setCategorySelectedExamining((categories) => ({
            ...categories,
            categoryContents: categories.categoryContents.map((content, cIndex) =>
                cIndex === contentsIndex
                    ? {
                          ...content,
                          categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                              qIndex === questionIndex
                                  ? { ...question, categoryContentAnswer: !contentAnswer }
                                  : question
                          ),
                      }
                    : content
            ),
        }));
    };
    // Xử lý nhập note của new tiền căn
    const handleAnswerValueNewPres = (contentsIndex, questionIndex, value) => {  
          if(typingRef.current){
              clearInterval(typingRef.current);
          }
          typingRef.current = setTimeout(() => {
            setDetailPatient((details) => ({
                ...details,
                categoryPres: details.categoryPres.map((cate) =>
                    cate.categoryOrder === categorySelectedExamining.categoryOrder
                    ?{
                        ...cate,
                        categoryContents: cate.categoryContents.map((content, cIndex) =>
                            cIndex === contentsIndex
                                ? {
                                      ...content,
                                      categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                          qIndex === questionIndex
                                              ? { ...question, categoryContentNote: value }
                                              : question
                                      ),
                                  }
                                : content
                        ),
                    }
                    :
                    cate
                )
            }))
            setCategorySelectedExamining((categories) => ({
                ...categories,
                categoryContents: categories.categoryContents.map((content, cIndex) =>
                    cIndex === contentsIndex
                        ? {
                              ...content,
                              categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                  qIndex === questionIndex
                                      ? { ...question, categoryContentNote: value }
                                      : question
                              ),
                          }
                        : content
                ),
            }));
          },500)
    };
    // ================ Nhập sổ khám
    // Xử lý chọn checkbox của new sổ khám
    const handleAnswerCheckNewMedicalBook = (categoryPatientsIndex, contentsIndex, questionIndex, contentAnswer) => {
        setHealthRecordsContents((books) => 
            books.map((book) => 
                book.medicalRegisterId === currentHealthRecordExamining.medicalRegisterId
                ?{
                    ...book,
                    categories: book.categories.map((cate, cateIndex) =>
                        cateIndex === categoryPatientsIndex
                        ?{
                            ...cate,
                            categoryContents: cate.categoryContents.map((content, cIndex) =>
                                cIndex === contentsIndex
                                    ? {
                                          ...content,
                                          categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                              qIndex === questionIndex
                                                  ? { ...question, categoryContentAnswer: !contentAnswer }
                                                  : question
                                          ),
                                      } : content
                            ),
                        } : cate
                    )
                } : book
            )
        )
        setCurrentHealthRecordExamining((book) => ({
            ...book,
            categories: book.categories.map((cate, cateIndex) =>
                cateIndex === categoryPatientsIndex
                ?{
                    ...cate,
                    categoryContents: cate.categoryContents.map((content, cIndex) =>
                        cIndex === contentsIndex
                            ? {
                                  ...content,
                                  categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                      qIndex === questionIndex
                                          ? { ...question, categoryContentAnswer: !contentAnswer }
                                          : question
                                  ),
                              }
                            : content
                    ),
                } : cate
            )
        }));
    };
    // Xử lý nhập note của new sổ khám
    const handleAnswerValueNewMedicalBook = (categoryPatientsIndex, contentsIndex, questionIndex, value) => {  
        if(typingRef.current){
            clearInterval(typingRef.current);
        }
        typingRef.current = setTimeout(() => {
            setHealthRecordsContents((books) => 
                books.map((book) => 
                    book.medicalRegisterId === currentHealthRecordExamining.medicalRegisterId
                    ?{
                        ...book,
                        categories: book.categories.map((cate, cateIndex) =>
                            cateIndex === categoryPatientsIndex
                            ?{
                                ...cate,
                                categoryContents: cate.categoryContents.map((content, cIndex) =>
                                    cIndex === contentsIndex
                                        ? {
                                              ...content,
                                              categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                                  qIndex === questionIndex
                                                      ? { ...question, categoryContentNote: value }
                                                      : question
                                              ),
                                          } : content
                                ),
                            } : cate
                        )
                    } : book
                )
            )
            setCurrentHealthRecordExamining((book) => ({
                ...book,
                categories: book.categories.map((cate, cateIndex) =>
                    cateIndex === categoryPatientsIndex
                    ?{
                        ...cate,
                        categoryContents: cate.categoryContents.map((content, cIndex) =>
                            cIndex === contentsIndex
                                ? {
                                      ...content,
                                      categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                          qIndex === questionIndex
                                              ? { ...question, categoryContentNote: value }
                                              : question
                                      ),
                                  }
                                : content
                        ),
                    } : cate
                )
            }));
        },500)
    };
    // ================ EDIT TIỀN CĂN
    // Xử lý chọn checkbox của edit tiền căn
    const handleAnswerCheckEditPres = (contentsIndex, questionIndex, contentAnswer) => {
        setDataUpdatePres((datas) => 
            datas.map((data) =>
                data.categoryId === selectUpdatePre
                ?{
                    ...data,
                    categoryContents: data.categoryContents.map((content, cIndex) =>
                        cIndex === contentsIndex
                            ? {
                                    ...content,
                                    categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                        qIndex === questionIndex
                                            ? { ...question, categoryContentAnswer: !contentAnswer }
                                            : question
                                    ),
                                }
                            : content
                    ),  
                }:
                data
            )
        )
    };
    // Xử lý nhập note của edit tiền căn
    const handleAnswerValueEditPres = (contentsIndex, questionIndex, value) => {  
          if(typingRef.current){
              clearInterval(typingRef.current);
          }
          typingRef.current = setTimeout(() => {
            setDataUpdatePres((datas) => 
                datas.map((data) =>
                    data.categoryId === selectUpdatePre
                    ?{
                        ...data,
                        categoryContents: data.categoryContents.map((content, cIndex) =>
                            cIndex === contentsIndex
                                ? {
                                        ...content,
                                        categoryContentQuestions: content.categoryContentQuestions.map((question, qIndex) =>
                                            qIndex === questionIndex
                                                ? { ...question, categoryContentNote: value }
                                                : question
                                        ),
                                    }
                                : content
                        ),  
                    }:
                    data
                )
            )
          },500)
    };
    //====================================================== END XỬ LÝ NHẬP DỮ LIỆU ==========================================================

    // Xử lý khi nhấn edit tiền căn
    const handleEditDataPredecessor = async (categoryId) => {
        setCurrentScreen(ScreenContent.LoadScreen);
        const index = dataUpdatePres.findIndex((pre) => pre.categoryId === categoryId);
        if(index === -1){
            const responseGetUpdatePre = await getUpdateCategoryPre(categoryId);
            
            if(responseGetUpdatePre.status === 200){
                setDataUpdatePres((prevData) => [
                    ...prevData,               
                    responseGetUpdatePre.data 
                ]);
                
            }else{
                toast.error(responseGetUpdatePre.data, {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            }
        }
        setSelectUpdatePre(categoryId);
        setCurrentScreen(ScreenContent.EditPredecessor);
    }
    // Xử lý khi nhấn hủy edit
    const handleCancelEditDataPredecessor = (categoryId) =>{
        setDataUpdatePres((prevData) => 
            prevData.filter((pre) => pre.categoryId !== categoryId)
        );
        if(selectUpdatePre === categoryId && currentScreen === ScreenContent.EditPredecessor){
            const predecessorItem = detailPatient.categoryPres.find((pre) => pre.categoryId === categoryId);
            if(!!predecessorItem){
                setOpenCollapseHealthRecords(false)
                setCategorySelectedExamining(predecessorItem);
                setCurrentScreen(ScreenContent.ClickPredecessor);
            }else{
                setCurrentScreen(ScreenContent.NoScreen);
            }
            
        }
    }
    // Kiểm tra có đang edit không.
    const isEditPredecessor = (categoryId) => {
        return (dataUpdatePres.findIndex((pre) => pre.categoryId === categoryId) !== -1)
    }

    // Xử lý save update tiền căn 
    const handleSaveEditPredecessor = async (categoryId) => {
        let predecessor = dataUpdatePres.find((pre) => pre.categoryId === categoryId);
        if(!!predecessor){
            predecessor = filterDataSaveEditPres(predecessor)
            const responseUpdatePre = await updateOneCategoryPre(predecessor);
            if(responseUpdatePre.status === 200){
                setDetailPatient((details) => ({
                    ...details,
                    categoryPres: details.categoryPres.map((cate) => 
                        cate.categoryId === categoryId
                        ?{
                            ...responseUpdatePre.data
                        }:cate
                    )
                }))
                setDataUpdatePres((prevData) => 
                    prevData.filter((pre) => pre.categoryId !== categoryId)
                );
                if(selectUpdatePre === categoryId && currentScreen === ScreenContent.EditPredecessor){
                    setOpenCollapseHealthRecords(false)
                    setCategorySelectedExamining(responseUpdatePre.data);
                    setCurrentScreen(ScreenContent.ClickPredecessor);
                    
                }
                toast.success(`Lưu tiền căn thành công`, {toastId: 'success1'});
                
            }else{
                toast.error(responseUpdatePre.data, {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            }
        }else{
            toast.error('Không tìm thấy tiền căn để lưu!', {
                position: toast.POSITION.BOTTOM_CENTER
              });
        }
        
    }
    // Lọc data để save edit tiền căn
    const filterDataSaveEditPres = (category) => {
        return {
                ...category,
                categoryContents: category.categoryContents.map((content) => ({
                    ...content,
                    categoryContentQuestions: content.categoryContentQuestions.filter(
                        (question) =>
                            question.categoryContentAnswer === true ||
                            (question.categoryContentNote != null &&
                            question.categoryContentNote.trim() !== "")
                    ),
                }))
                .filter((content) => content.categoryContentQuestions.length > 0),
            }
    };




    //chọn loại mục khám của một sổ khám bệnh
    const handleOpenHealthRecordsItem = (categoryPatientsIndex) => {
        setOpenCollapseHealthRecordsItem((prevOpen) => {
            const newOpen = [...prevOpen];
            newOpen[categoryPatientsIndex] = !newOpen[categoryPatientsIndex];
            return newOpen;
        });
    }
    const handleRenderExamsStatus = (status) => {
        if(status === 0 || status === 3){
          return (
            <div className='chipUnDoneExamms'><p className='chipLabel'>Chưa thực hiện</p></div>
          )
        }
        else if(status === 1){
          return (
            <div className='chipDoingExamms'><p className='chipLabel'>Đang thực hiện</p></div>
          )
        }
        else{
          return (
            <div className='chipDoneExamms'><p className='chipLabel'>Đã thực hiện</p></div>
          )
        }
    }
    const useStyles = makeStyles({
        root: {
        '&.Mui-disabled': {
            color: '#d32f2f', // Màu sắc khi checkbox bị vô hiệu hóa
        },
        '&.Mui-checked.Mui-disabled': {
            color: '#d32f2f', // Màu sắc khi checkbox bị vô hiệu hóa và đã được chọn
        },
        },
    });

    const classes = useStyles();
    
    //load mở tiếp tục khám cho state =1
    useEffect(() => {
        if(isLoadOpenExamining && dataPantientsReadyExamining.status === 1){
            handleBeginExaminingForPantient();
        }
        setIsLoadOpenExamining(false)
    },[isLoadOpenExamining === true])

    // update danh sách bệnh nhân
    useEffect(() => {
        if(listDataPatientsRegister.length > 0){
            const listPantientRegisterDone = listDataPatientsRegister.filter(patientsRegisterItem => patientsRegisterItem.state === 2) //đã khám
            const listPantientRegisterWaiting = listDataPatientsRegister.filter(patientsRegisterItem => patientsRegisterItem.state !== 2) //chờ khám và đang khám
            setListPantientChipState(
                [
                    { chipLabel: 'BN chờ khám', chipContent: listPantientRegisterWaiting.length,  listPatient:listPantientRegisterWaiting  },
                    { chipLabel: 'BN đã khám', chipContent: listPantientRegisterDone.length, listPatient: listPantientRegisterDone }
                ]
            );
        }
    },[listDataPatientsRegister])
    // Đồng bộ danh sách hiển thị khi chip thay đổi
    useEffect(() => {
        const currentList = listPantientChipState[activeChip.chipOrder]?.listPatient || [];
        setListDataPatientsRegisterSort(currentList);
    }, [activeChip, listPantientChipState]);
    useEffect(() => {
        if(loading === false && user.isLogin){
            if(user.positionName !== 'Doctor'){
                history.push('/404');
            }
            else if(loadingGetRegisterByDateNow){
                handleGetRegistersByDateNow(true);
                setLoadingGetRegisterByDateNow(false);
            }
        }
    }, [loading, user, loadingGetRegisterByDateNow])
    useEffect(() => {
        if(isDialogChangePasswordOpen === false){
            const handleKeyDown = (event) => {
                switch(event.keyCode){
                    case 112: // Nhấn F1 Hủy khám
                        if(isExamining){
                            event.preventDefault();
                            handleCancelExamining();
                        }
                        break;
                    case 113: // Nhấn F2 Bắt đầu khám, sửa đổi
                        event.preventDefault();
                        if(isExamining){
                            alert(`Bạn đang khám cho bệnh nhân ${dataPantientsReadyExamining.patientsName}!`);
                        }else{
                            handleBeginExaminingForPantient();
                        }
                        break;  
                    case 115: // Nhấn F4
                        if(isExamining && openModalCompleteExamining === false){
                            event.preventDefault();
                            handleCompleteExaminingButtonClick();
                        }
                        break;
                }
            }

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
        
    }, [dataPantientsReadyExamining, openModalCompleteExamining, openAlertProcessingBackdrop, isDialogChangePasswordOpen])

    // Đăng ký sự kiện nhận thông báo bn đăng ký khám khi component được mount
    const handleReceiveMessage = async (message) => {
        try {
            if (!!message) {
                
                if (message.type === SignalType.MEDICAL_REGISTER && message.data.userName !== user.userName) {
                    const filterPatientAllowed = await handleGetRegistersByDateNow();
                    if (
                        filterPatientAllowed.some((medical) => medical.patient.patientId === message.data.patientId) &&
                        message.data.msg !== ''
                    ) {
                        toast.success(message.data.msg, { toastId: 'success1', position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }else if(message.type == SignalType.PATIENT_CANCEL)
                {
                    const filterPatientAllowed = await handleGetRegistersByDateNow();
                    if(message.data.patientId === dataPantientsReadyExamining.patientsId && !filterPatientAllowed.some((medical) => medical.patient.patientId === message.data.patientId)){
                        
                        resetExaminingState();
                    }
                }
            }
        } catch (error) {
            console.error("Error in handleReceiveMessage:", error);
        }
    };
    useEffect(() => {
        
        const token = localStorage.getItem('jwt');
        startSignalRConnection(token).then(() => {
            onReceiveMessage((message) => handleReceiveMessage(message));
        });

    }, [])
    
    function ScreenContentUpdatePre () {
        const selectedCategory = dataUpdatePres.find((u) => u.categoryId === selectUpdatePre);
        return (
            selectUpdatePre != null && 
            <>
                <div className='content-examining'>
                    {
                        <>
                            {selectedCategory == null || selectedCategory.categoryContents.length <= 0 ? 
                                <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                    <Typography variant='h6'>Chưa có nội dung</Typography>
                                </Box>
                            :
                            <>
                                <Typography variant='h6' sx={{fontWeight: 'bolder', fontSize: '1.2rem', textAlign: 'center', color: 'deeppink', mb: 0.2, mt:1}}>Chỉnh sửa "{selectedCategory.categoryName}"</Typography>
                                {    selectedCategory.categoryContents.map((categoryContentsItem, categoryContentsIndex) => (
                                    <Box key={categoryContentsIndex} sx={categoryContentsItem.categoryContentTitle === null ? {marginTop: '30px'} : {}}>
                                        <Typography variant='h6' sx={{fontWeight: 'bolder', textAlign: 'center', fontSize: '1.2rem', mb: 0.2, mt:1}}>{categoryContentsItem.categoryContentTitle || ''}</Typography>
                                        {/* Thanh tên cột */}
                                        <Box key={`categoryContentQuestionName`}>
                                            <Grid container rowSpacing={0}>
                                                <Grid item xs={4} sx={{border: '2px solid rgb(218,220,224)', borderRight: '0px', p: 0.7}}>
                                                        <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder'}}>{categoryContentsItem.categoryContentName}</Typography>
                                                </Grid>
                                                {categoryContentsItem.categoryContentCheck != null && (
                                                    <Grid item xs={1} sx={{border: '2px solid rgb(218,220,224)', borderRight: '0px', p: 0.7, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <CheckBoxIcon sx={{fontSize: '1.22rem', color: 'gray'}}/><Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder', margin: 'auto', lineHeight: '1.6'}}>{categoryContentsItem.categoryContentCheck}</Typography>
                                                    </Grid>
                                                )}
                                                

                                                <Grid item xs={categoryContentsItem.categoryContentCheck != null?7:8} sx={{border: '2px solid rgb(218,220,224)', p: 0.7}}>
                                                    <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder', ml: 0.5}}>{categoryContentsItem.categoryContentText}</Typography>
                                                </Grid>
                                                    
                                                    
                                            </Grid>
                                        </Box>
                                        {/* Các câu hỏi */}
                                        {categoryContentsItem.categoryContentQuestions.map((questionItem, questionIndex) => (
                                            <Box key={`categoryContentQuestionName ${questionIndex}`}>
                                                <Grid container rowSpacing={0}>
                                                    <Grid item xs={4} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', alignItems: 'center'}}>
                                                        <Typography variant='subtitle1' sx={{fontSize: '1.2rem'}}>{questionItem.categoryContentQuestionName}</Typography>
                                                    </Grid>

                                                    {questionItem.categoryContentQuestionType === 'check' &&(
                                                        <Grid item xs={1} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', justifyContent: 'center'}}>
                                                                <Checkbox
                                                                checked={questionItem.categoryContentAnswer} 
                                                                classes={{ root: classes.root }} color='error' 
                                                                sx={{'& .MuiSvgIcon-fontSizeMedium': {fontSize: '1.2rem'}}} 
                                                                onClick={() => handleAnswerCheckEditPres(categoryContentsIndex, questionIndex, questionItem.categoryContentAnswer)} 
                                                                />
                                                            </Grid>
                                                    )}
                                                    <Grid item xs={questionItem.categoryContentQuestionType === 'check'?7:8}>
                                                        <div className='textfield-for-answer'>
                                                            <div className='suggest-note' key={`question ${questionIndex}- categoryContentOrder ${categoryContentsIndex} - questionOrder ${questionItem.categoryContentQuestionOrder}`} >                                                                          
                                                                <TextareaAutosize className='textarea-autosize' rows={1} style={{ fontSize: '1.2rem'}}
                                                                    onChange={(e) => handleAnswerValueEditPres(categoryContentsIndex, questionIndex, e.target.value)} 
                                                                    defaultValue={questionItem.categoryContentNote}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                        
                                                    
                                                    
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </>
                            }
                        </>
                    }
                </div>        
            </>
        )
    }

    return (
        <>
            <Container maxWidth="xl" sx={{mt: 2.5, pt:2}}>
                <Box sx={{ bgcolor: '#fff', height: 'auto'}} >
                    <Grid container spacing={2} sx={{pl: 0.6, pr: 0.6}}>

                        <Grid item xs={isShowInfoPatient?5:12} sx={{pt:"0px !important "}}>
                            <TableContainer className="box-webkit-scrollbar" component={Paper} sx={{ height: isShowInfoPatient?isExamining?"388px":'775px':'800px', boxShadow: 5, borderRadius: '10px' }}>
                                {loadingPatient ?
                                    <>
                                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#e0e0e0', height: '100%'}}>
                                            <CircularProgress />
                                            <Typography variant='subtitle1' sx={{mt: 1}}>Đang tải dữ liệu, hãy chờ một chút...</Typography>
                                        </Box>        
                                    </>
                                    :
                                    <>
                                        <Box>
                                            <Stack direction='row' spacing={1} sx={{justifyContent: 'center', mt: 1.4}}>
                                                {listPantientChipState?.length > 0 && listPantientChipState.map((pantientChipStateItem, pantientChipStateIndex) => (
                                                    <Badge
                                                        badgeContent={pantientChipStateItem.chipContent}
                                                        color="error"
                                                        key={`chipPantientState-${pantientChipStateItem.chipLabel}`}
                                                        sx={{ '.MuiBadge-badge': { fontSize: '0.8rem' } }}
                                                    >
                                                        <Chip
                                                        label={pantientChipStateItem.chipLabel}
                                                        color={pantientChipStateIndex === activeChip.chipOrder ? 'primary' : 'default'}
                                                        onClick={() => handleSelectedChip(pantientChipStateIndex, pantientChipStateItem.chipLabel)}

                                                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '120px' }}
                                                        />
                                                    </Badge>
                                                ))}
                                            </Stack>

                                            <Box sx={{mt: 0.2, position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                                <CachedIcon sx={{color: 'brown', fontWeight: 'bolder', position: 'absolute', right: 50, top: -5, fontSize: '2.5rem', cursor: 'pointer'}} titleAccess='Tải lại' onClick={() => handleReloadGetRegistersByDateNow()}/>
                                                <Typography variant="h6" sx={{textAlign: 'center', fontSize: '1.12rem'}}>Danh sách {activeChip.chipLabel} ngày {moment().format("DD/MM/YYYY")}</Typography>
                                            </Box>
                                            
                                            <Box sx={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
                                                <TextField sx={{mb: 0.6, width: 360, '& .MuiInputBase-inputSizeSmall': {textAlign: 'center'}}} size="small" 
                                                    variant="outlined" placeholder='Tìm với Mã BN, Tên BN hoặc SĐT' value={searchPatientsQuery} onChange={(e) => handleSearchPatient(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'><SearchIcon/></InputAdornment>  
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                              {searchPatientsQuery !== '' && (
                                                                <CloseIcon
                                                                  sx={{ cursor: 'pointer' }}
                                                                  titleAccess="Xóa"
                                                                  onClick={() => {
                                                                    setSearchPatientsQuery('');
                                                                    handleSearchPatient('');
                                                                  }}
                                                                />
                                                              )}
                                                            </InputAdornment>
                                                          )
                                                    }}>
                                                </TextField>
                                            </Box>
                                        </Box>
                                
                                        <Table stickyHeader >
                                            <TableHead>
                                                <TableRow sx={{"& th": {color: "rgba(96, 96, 96)", backgroundColor: "pink"}}}>
                                                    <TableCell align='left' sx={{fontSize: '0.95rem'}}>STT</TableCell>
                                                    <TableCell align='left' sx={{fontSize: '0.95rem'}}>Mã bệnh nhân</TableCell>
                                                    <TableCell align="left" sx={{fontSize: '0.95rem'}}>Họ tên</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {listDataPatientsRegisterSort.map((dataPantientItem, dataPantientIndex) => (
                                                    <TableRow hover role="checkbox" key={dataPantientItem.order}
                                                        sx={{ cursor: 'pointer', backgroundColor: dataPantientItem.patient.patientId === dataPantientsReadyExamining.patientsId ? '#7becfa' : '#fff', 
                                                        ':hover': {backgroundColor: dataPantientItem.patient.patientId === dataPantientsReadyExamining.patientsId ? '#6fd4e1 !important' : 'rgba(0, 0, 0, 0.1) !important'} }} 
                                                        onClick={() => handleSelectPantientExamining(dataPantientItem)} 
                                                    
                                                    >
                                                        <TableCell align='left' sx={{width: '20px'}}>{dataPantientItem.order}</TableCell>
                                                        <TableCell align='left' sx={{width: '150px'}}>{dataPantientItem.patient.patientCode}</TableCell>
                                                        <TableCell align='left' sx={{width: '240px'}}>{dataPantientItem.patient.fullName}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                }
                            </TableContainer>

                            {/* cây thư mục khám */}
                            {isExamining && (
                                <Box className="box-webkit-scrollbar" style={{width: '100%', marginTop: '10px', borderRadius: '10px', border: '2px solid red', height: '380px', overflow: 'auto'}} >
                                {
                                    detailPatient.length === 0 && loadingCategoryExamining === false ?
                                        null
                                    :
                                    <>
                                        {loadingCategoryExamining ?
                                            <Box sx={{backgroundColor: '#e0e0e0', borderRadius: '10px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                                <CircularProgress />
                                                <Typography variant='subtitle1' sx={{mt: 1}}>Đang tải dữ liệu cây thư mục khám, hãy chờ một chút...</Typography>
                                            </Box>
                                        :
                                            <>
                                                <div className='category-scrollbar'>
                                                    <List sx={{p: 1, display: 'inline-grid'}}>
                                                        {/* Danh sách tiền căn */}
                                                        {detailPatient.categoryPres.map((predecessorItem, predecessorIndex) => (
                                                            <Box sx={{display: 'inline-flex', pb:1}} key={`category ${predecessorIndex}`}>
                                                                <ListItemButton
                                                                    sx={{
                                                                    width: 'fitContent', pt: '4px', pb: '4px', borderRadius: '8px', ':hover': {backgroundColor: currentScreen == ScreenContent.ClickPredecessor && categorySelectedExamining.categoryName === predecessorItem.categoryName ? '#ffc107' : 'rgba(0, 0, 0, 0.1)'},
                                                                    backgroundColor:currentScreen == ScreenContent.ClickPredecessor && categorySelectedExamining.categoryName === predecessorItem.categoryName ? '#ffd54f' : ''}} 
                                                                    onClick={() => handleSelectCategoryClick(predecessorItem)}
                                                                >
                                                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                                        <ListItemIcon sx={{minWidth: '30px', '& .MuiSvgIcon-fontSizeMedium': {fontSize: '1.2rem', color: '#2962ff'}}}><SendIcon /></ListItemIcon>
                                                                        <ListItemText primary={predecessorItem.categoryName} sx={{'& .MuiListItemText-primary': {fontSize: '1.25rem', fontWeight: 'bolder', color: 'deeppink'}}}/>
                                                                    </Box>
                                                                </ListItemButton>

                                                                {predecessorItem.newCategoryPre === true && predecessorItem.newPredecessor === true ? 
                                                                    null
                                                                :
                                                                    <>
                                                                        {predecessorItem.newCategoryPre === false  ?
                                                                            <>  
                                                                                <CheckCircleIcon sx={{color: 'green', fontSize: '1.4rem', margin: 'auto'}} titleAccess='đã theo dõi'/>
                                                                            </>
                                                                        :
                                                                            null
                                                                        }

                                                                        {detailPatient.newPredecessor === false &&(
                                                                            isEditPredecessor(predecessorItem.categoryId) ?
                                                                            <>
                                                                                <IconButton edge="end" sx={{ml: 0.5, zIndex: 100}} 
                                                                                    onClick={() => handleSaveEditPredecessor(predecessorItem.categoryId)}
                                                                                    >
                                                                                    <BeenhereIcon sx={{color: 'red', fontSize: '1.6rem'}} titleAccess='Lưu'/>
                                                                                </IconButton>

                                                                                <IconButton edge="end" sx={{ml: 0.5, zIndex: 100}} 
                                                                                    onClick={() => handleCancelEditDataPredecessor(predecessorItem.categoryId)}
                                                                                    >
                                                                                    <CancelIcon sx={{color: 'red', fontSize: '1.6rem'}} titleAccess='Hủy'/>
                                                                                </IconButton>
                                                                            </>  
                                                                            :
                                                                            <>  
                                                                                <IconButton edge="end" sx={{ml: 0.5, zIndex: 100}} 
                                                                                    onClick={() => handleEditDataPredecessor(predecessorItem.categoryId)}
                                                                                    >
                                                                                    <EditIcon sx={{color: 'blue', fontSize: '1.6rem'}} titleAccess='Sửa'/>
                                                                                </IconButton>
                                                                            </>
                                                                             
                                                                        )}                                  
                                                                    </>
                                                                }
                                                            </Box>
                                                        ))}

                                                        <ListItemButton 
                                                            sx={{pt: '4px', pb: '4px',pl:'0', borderRadius: '8px', backgroundColor: currentScreen == ScreenContent.ClickListBook? '#ffd54f':'' ,
                                                                ':hover': {backgroundColor:  currentScreen == ScreenContent.ClickListBook?  '#ffc107': 'rgba(0, 0, 0, 0.1)'}}} 
                                                            
                                                        >
                                                            <ListItemIcon sx={{minWidth: '30px', maxHeight: '20px', alignItems:'center', '& .MuiSvgIcon-fontSizeMedium': {fontSize: '3.0rem', color: '#2962ff', pl:'16px', pr: '12px'}}}>
                                                                <SendIcon
                                                                     onClick={() => handleSelectHealthRecords()}
                                                                    sx={{transform: isOpenCollapseHealthRecords ?'rotate(90deg)' : 'rotate(0deg)'}} 
                                                                />
                                                            </ListItemIcon>

                                                            <ListItemText primary={'Sổ sức khỏe'} 
                                                                onClick={() => handleSelectTitleHealthRecords()}
                                                                sx={{'& .MuiListItemText-primary': {fontSize: '1.25rem', fontWeight: 'bolder', color: 'deeppink'}}}
                                                            />                                                    
                                                        </ListItemButton>
                                                        <Collapse in={isOpenCollapseHealthRecords} timeout="auto" unmountOnExit>
                                                            <div style={{ paddingLeft: '24px' }}>
                                                                {detailPatient.healthRecords.map((healthRecordsItem, healthRecordsIndex) => (
                                                                    <ListItemButton key={`healthRecords ${healthRecordsIndex}`} 
                                                                        sx={{pt: '0px', pb: '0px', width: 'auto', borderRadius: '8px', 
                                                                            backgroundColor:currentScreen === ScreenContent.ClickOneBook && healthRecordsItem.medicalRegisterId == currentHealthRecordExamining.medicalRegisterId?'#ffd54f': '',
                                                                            ':hover': {backgroundColor:currentScreen === ScreenContent.ClickOneBook && healthRecordsItem.medicalRegisterId == currentHealthRecordExamining.medicalRegisterId?'#ffc107': 'rgba(0, 0, 0, 0.1)'}}} 
                                                                        onClick={() => handleSelectHealthRecordsItem(healthRecordsIndex)}
                                                                        >
                                                                        <ListItemIcon sx={{minWidth: '30px', '& .MuiSvgIcon-fontSizeMedium': {fontSize: '1.2rem', color: healthRecordsItem.state === 1 ? 'red' : '#009688'}}}>
                                                                            <FiberManualRecordIcon sx={{transform: categorySelectedExamining.categoryName === 'Sổ sức khỏe' ? 'rotate(90deg)' : 'rotate(0deg)'}} />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={healthRecordsItem.examinationName} sx={{'& .MuiListItemText-primary': {fontSize: '1.25rem', fontWeight: 'bolder', color: 'deeppink'}}}/>
                                                                    </ListItemButton>
                                                                ))}
                                                            </div>
                                                        </Collapse>
                                                    </List>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                            )}
                            {/* Danh sách các nút nhấn */}
                            {(
                                dataPantientsReadyExamining.stateRegisterInitial === 0 || dataPantientsReadyExamining.stateRegisterInitial === 3 ?
                                isExamining ?
                                    <div className='instruction' style={{display: 'flex', marginTop: '4.5px'}}>
                                        <Button variant="contained" color="error" sx={{mr: 2}} onClick={() => handleCancelExamining()}>Hủy khám (f1)</Button>
                                        <Button variant="contained" color="primary" 
                                        onClick={() => handleCompleteExaminingButtonClick()}
                                            >Kết thúc khám (f4)</Button>
                                    </div>
                                :
                                    <div className='instruction' style={{display: 'flex', marginTop: '4.5px'}}>
                                        <Button variant="contained" color="secondary" 
                                        onClick={() => handleStartExaminingButtonClick()}
                                        >Bắt đầu khám (f2)</Button>
                                    </div>
                                
                            :
                            
                                dataPantientsReadyExamining.stateRegisterInitial === 2 ?
                                    isExamining ? 
                                        <div className='instruction' style={{display: 'flex', marginTop: '4.5px'}}>
                                            <Button variant="contained" color="error" sx={{mr: 2}} 
                                            onClick={() => handleCancelExamining()}
                                            >Hủy thay đổi (f1)</Button>
                                            <Button variant="contained" color="primary" 
                                            onClick={() => handleCompleteExaminingButtonClick()}
                                            >Kết thúc khám (f4)</Button>
                                        </div>
                                    :
                                        <div className='instruction' style={{display: 'flex', marginTop: '4.5px'}}>
                                        {/* <Button variant="contained" color="error" sx={{mr: 2}} onClick={() => handleDeleteExamining()}>Xóa khám (f1)</Button> */}
                                        <Button variant="contained" color="secondary" 
                                        onClick={() => handleStartExaminingButtonClick()}
                                        >Sửa đổi (f2)</Button>
                                    </div>
                                :
                                    null
                            )}
                        </Grid>
                        {isShowInfoPatient && (
                            <Grid item xs={7} sx={{pt:"0px !important "}}>
                                {/* thông tin bệnh nhân */}
                                <Box className="box-webkit-scrollbar" sx={{boxShadow: 4, borderRadius: '20px', border: '2px solid blue', height: '230px', mb: '8px', overflow: 'auto'}}>
                                    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}> 
                                        <Typography variant='h6' sx={{color: 'blue', mt: 0.6, mb: 0.6, fontWeight: 'bolder', fontSize: '1.2rem', textAlign: 'center'}}>Thông tin bệnh nhân</Typography>
                                        {!isExamining && (<CloseIcon sx={{color: 'gray',fontWeight: 'bolder',fontSize: '2.5rem',cursor: 'pointer',position: 'absolute', right:5}}titleAccess="Đóng" 
                                        onClick={() => hanldeCloseInfoPatient()}
                                            />)}
                                    </Box>
                                    
                                    <Box sx={{pl: 2.8, pr: 2.8, mt: 1}}>
                                        {loadingInfoPatient ?
                                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 4}}>
                                                <CircularProgress color="inherit"/>
                                                <Typography variant='subtitle1' sx={{mt: 1}}>Đang tải thông tin bệnh nhân, hãy chờ một chút...</Typography>
                                            </Box>
                                        :
                                            <>
                                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4}}>

                                                    <Grid item xs={4} sx={{display: 'inline-flex'}}>
                                                        <PinIcon sx={{color: 'tomato'}}/><Typography variant='subtitle1' sx={{ml: 1}}>{`Mã BN: ${dataPantientsReadyExamining.patientCode || ''}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{display: 'inline-flex'}}>
                                                        <PersonIcon sx={{color: 'tomato'}}/><Typography variant='subtitle1' sx={{ml: 1}}>{`Họ tên: ${dataPantientsReadyExamining.patientsName || ''}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sx={{display: 'inline-flex'}}>
                                                        {dataPantientsReadyExamining.patientsGender === true ? <MaleIcon sx={{color: 'coral', fontSize: '27px'}}/> : <FemaleIcon sx={{color: 'coral', fontSize: '27px'}}/>}
                                                        <Typography variant='subtitle1' sx={{ml: 1}}>
                                                            {`Giới tính: ${dataPantientsReadyExamining.patientsGender !== '' ? dataPantientsReadyExamining.patientsGender === true ? 'Nam' : 'Nữ' : ''}`}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={4} sx={{display: 'inline-flex'}}>
                                                        <CalendarMonthIcon sx={{color: 'tomato'}}/><Typography variant='subtitle1' sx={{ml: 1}}>Ngày sinh: {dataPantientsReadyExamining.patientsDOB ? moment(dataPantientsReadyExamining.patientsDOB).format("DD/MM/YYYY") : ''}</Typography>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{display: 'inline-flex'}}>
                                                        <InfoIcon sx={{color: 'tomato'}}/><Typography variant='subtitle1' sx={{ml: 1}}>{`Tháng tuổi: ${dataPantientsReadyExamining.patientsMonthsOld || ''}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sx={{display: 'inline-flex'}}>
                                                        <PhoneIcon sx={{color: 'tomato'}}/><Typography variant='subtitle1' sx={{ml: 1}}>{`Sdt: ${dataPantientsReadyExamining.patientsPhone || ''}`}</Typography>
                                                    </Grid>
                                                    
                                                    <Grid item xs={4} sx={{display: 'inline-flex'}}>
                                                        <GiBodyHeight style={{color: 'tomato', fontSize: '1.42rem', marginLeft: '2px'}}/>
                                                        <Typography variant='subtitle1' sx={{ml: 0.8}}>
                                                            {`Chiều cao: ${dataPantientsReadyExamining.patientsHeight ? dataPantientsReadyExamining.patientsHeight + ' cm' : ''}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{display: 'inline-flex'}}>
                                                        <GiWeightScale style={{color: 'tomato', fontSize: '1.42rem'}}/>
                                                        <Typography variant='subtitle1' sx={{ml: 1}}>
                                                            {`Cân nặng: ${dataPantientsReadyExamining.patientsWeight ? dataPantientsReadyExamining.patientsWeight + ' kg' : ''}`}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={3} sx={{display: 'inline-flex'}}>
                                                        <TbRulerMeasure style={{color: 'tomato', fontSize: '1.42rem'}}/>
                                                        <Typography variant='subtitle1' sx={{ml: 1}}>
                                                            {`Vòng đầu: ${dataPantientsReadyExamining.patientsHeadCircumference ? dataPantientsReadyExamining.patientsHeadCircumference + ' cm' : ''}`}
                                                        </Typography>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sx={{display: 'inline-flex'}}>
                                                        <Home sx={{color: 'tomato'}}/><Typography variant='subtitle1' sx={{ml: 1}}>{`Địa chỉ: ${dataPantientsReadyExamining.patientsAddress || ''}`}</Typography>
                                                    </Grid> 

                                                    {previewPredecessor ? 
                                                        <>
                                                            <Grid item xs={12} >
                                                                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                                                                    {previewPredecessor.map((item, index) => (
                                                                        <Typography variant='subtitle1' sx={{ml: 0.5, fontSize: '1.1rem', color: 'mediumblue', fontWeight: 'bolder'}} key={index} >{`${item.categoryContentQuestionName}: ${item.categoryContentAnswer ? 'có' : ''} ${item.categoryContentNote !== null ? `${item.categoryContentQuestionType == "check" && "("}${item.categoryContentNote}${item.categoryContentQuestionType == "check" && ")"}` : ''}, `}</Typography>
                                                                    ))}
                                                                </Box>
                                                                
                                                            </Grid> 
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Box>
                                </Box>
                                {/* nội dung khám */}
                                <div className='container-content-examining'>
                                    {/* Không chọn gì */}
                                    {currentScreen === ScreenContent.NoScreen && null}
                                    {/* Lúc vừa chọn bệnh nhân, nếu có kỳ khám cũ sẽ hiện ra */}
                                    {currentScreen === ScreenContent.ClickPatient && (
                                            loadingPrevDataExamining ? 
                                            <>
                                                <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                                    <CircularProgress />
                                                    <Typography variant='subtitle1' sx={{mt: 1}}>Đang tải dữ liệu kỳ khám gần nhất, hãy chờ một chút...</Typography>
                                                </Box>
                                            </>
                                        :
                                        detailPatient ? 
                                            detailPatient.healthRecords.length === 1 && detailPatient.healthRecords[0].medicalBookId === null ? 
                                                <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                                    <Typography variant='h6'>Bệnh nhân không có kỳ khám trước</Typography>
                                                </Box>
                                            :
                                                <div className='content-examining'><PreviewLastExamining prevDataExamining={detailPatient} /></div>
                                        :
                                            null
                                    )}
                                    {/* Lúc chọn Tiền căn */}
                                    {currentScreen === ScreenContent.ClickPredecessor && (
                                        categorySelectedExamining.categoryContents && 
                                        <>
                                            <div className='content-examining'>
                                                {
                                                    <>
                                                        {categorySelectedExamining.categoryContents.length <= 0 ? 
                                                            <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                                                <Typography variant='h6'>Chưa có nội dung</Typography>
                                                            </Box>
                                                        :
                                                        categorySelectedExamining.categoryContents.map((categoryContentsItem, categoryContentsIndex) => (
                                                            <Box key={categoryContentsIndex} sx={categoryContentsItem.categoryContentTitle === null ? {marginTop: '30px'} : {}}>
                
                                                                <Typography variant='h6' sx={{fontWeight: 'bolder', textAlign: 'center', fontSize: '1.2rem', mb: 0.2, mt:1}}>{categoryContentsItem.categoryContentTitle || ''}</Typography>
                                                                {/* Thanh tên cột */}
                                                                <Box key={`categoryContentQuestionName`}>
                                                                    <Grid container rowSpacing={0}>
                                                                        <Grid item xs={4} sx={{border: '2px solid rgb(218,220,224)', borderRight: '0px', p: 0.7}}>
                                                                                <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder'}}>{categoryContentsItem.categoryContentName}</Typography>
                                                                        </Grid>
                                                                        {categoryContentsItem.categoryContentCheck != null && (
                                                                            <Grid item xs={1} sx={{border: '2px solid rgb(218,220,224)', borderRight: '0px', p: 0.7, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                                <CheckBoxIcon sx={{fontSize: '1.22rem', color: 'gray'}}/><Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder', margin: 'auto', lineHeight: '1.6'}}>{categoryContentsItem.categoryContentCheck}</Typography>
                                                                            </Grid>
                                                                        )}
                                                                        

                                                                        <Grid item xs={categoryContentsItem.categoryContentCheck != null?7:8} sx={{border: '2px solid rgb(218,220,224)', p: 0.7}}>
                                                                            <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder', ml: 0.5}}>{categoryContentsItem.categoryContentText}</Typography>
                                                                        </Grid>
                                                                            
                                                                           
                                                                    </Grid>
                                                                </Box>
                                                                {/* Các câu hỏi */}
                                                                {categoryContentsItem.categoryContentQuestions.map((questionItem, questionIndex) => (
                                                                    <Box key={`categoryContentQuestionName ${questionIndex}`}>
                                                                        <Grid container rowSpacing={0}>
                                                                            <Grid item xs={4} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', alignItems: 'center'}}>
                                                                                <Typography variant='subtitle1' sx={{fontSize: '1.2rem'}}>{questionItem.categoryContentQuestionName}</Typography>
                                                                            </Grid>

                                                                            {questionItem.categoryContentQuestionType === 'check' ?
                                                                                <Grid item xs={1} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', justifyContent: 'center'}}>
                                                                                        <Checkbox disabled={!detailPatient.newPredecessor} 
                                                                                        checked={questionItem.categoryContentAnswer} 
                                                                                        classes={{ root: classes.root }} color='error' 
                                                                                        sx={{'& .MuiSvgIcon-fontSizeMedium': {fontSize: '1.2rem'}}} 
                                                                                        onClick={() => handleAnswerCheckNewPres(categoryContentsIndex, questionIndex, questionItem.categoryContentAnswer)} 
                                                                                        />
                                                                                    </Grid>
                                                                                :
                                                                                <Grid item xs={1}></Grid>
                                                                            }
                                                                            <Grid item xs={7}>
                                                                                <div className='textfield-for-answer'>
                                                                                    <div className='suggest-note' key={`question ${questionIndex} - category ${categorySelectedExamining.categoryOrder} - categoryPresOrder ${contentCategorySelectedExamining.categoryPresOrder} - categoryContentOrder ${categoryContentsIndex} - isLock ${contentCategorySelectedExamining.isLock} - questionOrder ${questionItem.categoryContentQuestionOrder}`} >                                                                          
                                                                                        <TextareaAutosize className='textarea-autosize' rows={1} disabled={!detailPatient.newPredecessor} style={{ fontSize: '1.2rem'}}
                                                                                            onChange={(e) => handleAnswerValueNewPres(categoryContentsIndex, questionIndex, e.target.value)} 
                                                                                            defaultValue={questionItem.categoryContentNote}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                                
                                                                           
                                                                            
                                                                        </Grid>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        ))
                                                        }
                                                    </>
                                                }
                                            </div>        
                                        </>
                                        
                                    )}
                                    {/* Lúc chọn Tiền căn để edit */}
                                    {currentScreen === ScreenContent.EditPredecessor && (
                                        <ScreenContentUpdatePre/>
                                    )}
                                    {/* Lúc chọn danh sách sổ khám */}
                                    {currentScreen === ScreenContent.ClickListBook && (
                                        <>
                                            <Typography variant='h6' sx={{fontWeight: 'bolder', textAlign: 'center', fontSize: '1.2rem', mb: 0.5, mt:1}}>Khám và theo dõi sức khỏe định kỳ</Typography>
                                            <TableContainer component={Paper}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow sx={{"& th": {color: "rgba(96, 96, 96)", backgroundColor: "pink"}}}>
                                                            <TableCell align='left' sx={{fontSize: '0.9rem', color: 'black'}}>Kỳ khám</TableCell>
                                                            <TableCell align="left" sx={{fontSize: '0.9rem'}}>Trạng thái</TableCell>
                                                            <TableCell align="left" sx={{fontSize: '0.9rem'}}>Ngày khám</TableCell>
                                                            <TableCell align="left" sx={{fontSize: '0.9rem'}}>Bác sĩ khám</TableCell>
                                                            <TableCell align="left" sx={{fontSize: '0.9rem'}}>Kết luận</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody sx={{borderRadius: '0px'}}>
                                                        {detailPatient.healthRecords.map((healthRecordsItem, healthRecordsIndex) => (
                                                            <TableRow hover role="checkbox" 
                                                            onClick={() => handleSelectHealthRecordsItem(healthRecordsIndex)}
                                                            key={healthRecordsIndex}>
                                                                <TableCell align='left' sx={{width: '158px'}}><DescriptionIcon sx={{mr: 0.5, mb: 0.4, color: 'dodgerblue'}}/>{healthRecordsItem.examinationName}</TableCell>
                                                                <TableCell align='left' sx={{width: '144px'}}>{handleRenderExamsStatus(healthRecordsItem.state)}</TableCell>
                                                                <TableCell align='left' sx={{width: '108px'}}>{moment(healthRecordsItem.timeRegister).format("DD/MM/YYYY")}</TableCell>
                                                                <TableCell align='left' sx={{width: '115px'}}>{healthRecordsItem.doctorFullName}</TableCell>
                                                                <TableCell align='left' sx={{width: 'auto'}}>{healthRecordsItem.conclusion}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                    {/* Lúc chọn cụ thể 1 sổ khám */}
                                    {currentScreen === ScreenContent.ClickOneBook && (
                                        
                                        <>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                                                <IconButton  onClick={() => handleSelectTitleHealthRecords()} ><ArrowBackIcon /></IconButton>
                                                <Typography variant="h6" sx={{ fontWeight: 'bolder', textAlign: 'center', fontSize: '1.2rem', lineHeight: 1, flex: 1 }}>
                                                    {currentHealthRecordExamining ? currentHealthRecordExamining.examinationName : ''}
                                                </Typography>
                                            </Box>
                                            <div className='health-record-content'>
                                                {currentHealthRecordExamining && ( 
                                                    <>
                                                    <List sx={{p: 0}}>
                                                        {currentHealthRecordExamining.categories.map((categoryPatientsItem, categoryPatientsIndex) => (
                                                            <div key={`categoryPatients ${categoryPatientsIndex}`}>
                                                                <ListItemButton     
                                                                    sx={{pt: '0px', pb: '0px', borderRadius: '8px', ':hover': {backgroundColor: 'rgba(0, 0, 0, 0.1)'}}}
                                                                    onClick={() => handleOpenHealthRecordsItem(categoryPatientsIndex)}
                                                                >
                                                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                                        <ListItemIcon sx={{minWidth: '30px', '& .MuiSvgIcon-fontSizeMedium': {fontSize: '1rem', color: '#2962ff', transform: isOpenCollapseHealthRecordsItem[categoryPatientsIndex] === true ? 'rotate(90deg)' : 'rotate(0deg)'}}}><SendIcon /></ListItemIcon>
                                                                        <ListItemText primary={categoryPatientsItem.categoryName} sx={{'& .MuiListItemText-primary': {fontSize: '1.25rem', fontWeight: 'bolder', color: 'deeppink'}}}/>
                                                                    </Box>
                                                                </ListItemButton>

                                                                <Collapse in={isOpenCollapseHealthRecordsItem[categoryPatientsIndex]} timeout="auto" unmountOnExit>
                                                                    {categoryPatientsItem.categoryContents.map((categoryPatientsContentsItem, contentsIndex) => (
                                                                        <div className='header-health-record' key={contentsIndex} style={{marginTop: '15px', marginBottom: '15px', border: '2px solid #00bcd4', borderRadius: '20px'}}>
                                                                            <Typography variant='subtitle1' sx={{fontWeight: 'bolder', textAlign: 'center'}}>{categoryPatientsContentsItem.categoryContentTitle}</Typography>
                                                                        
                                                                            <div className='content-health-record' style={{padding: '10px'}} >  
                                                                                <Grid container rowSpacing={0}>
                                                                                    <Grid item xs={4} sx={{border: '2px solid rgb(218,220,224)', borderRight: '0px', p: 0.7}}>
                                                                                        <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder'}}>{categoryPatientsContentsItem.categoryContentName}</Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={3} sx={{border: '2px solid rgb(218,220,224)', borderRight: '0px', p: 0.7, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                                        <CheckBoxIcon sx={{fontSize: '1.2rem', color: 'gray', mr: 0.4}}/>
                                                                                        <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder', lineHeight: '1.6'}}>{categoryPatientsContentsItem.categoryContentCheck}</Typography>
                                                                                    </Grid>
                                                                                
                                                                                    <Grid item xs={5} sx={{border: '2px solid rgb(218,220,224)', p: 0.7}}>
                                                                                        <Typography variant='subtitle1' sx={{color: 'blue', fontWeight: 'bolder', ml: 0.5}}>{categoryPatientsContentsItem.categoryContentText}</Typography>
                                                                                    </Grid> 

                                                                                    {categoryPatientsContentsItem.categoryContentQuestions.map((questionItem, questionIndex) => (
                                                                                        <Box key={`questionItem ${questionIndex} examinationName ${contentCategorySelectedExamining.examinationName}`} style={{display: 'flex', width: '100%'}}>
                                                                                            <Grid item xs={4} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', alignItems: 'center'}}>
                                                                                                <Typography variant='subtitle1' sx={{fontSize: '1rem'}}>{questionItem.categoryContentQuestionName}</Typography>
                                                                                            </Grid>    

                                                                                            {questionItem.categoryContentQuestionType === 'check' ?
                                                                                                
                                                                                                    <Grid item xs={3} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', justifyContent: 'center'}}>
                                                                                                        <Checkbox classes={{ root: classes.root }} checked={questionItem.categoryContentAnswer === true ? true : false }
                                                                                                            disabled={!(dataPantientsReadyExamining.status === 1)}
                                                                                                            sx={{'& .MuiSvgIcon-fontSizeMedium': {fontSize: '1.2rem'}}} color='error'
                                                                                                            onClick={(e) => handleAnswerCheckNewMedicalBook(categoryPatientsIndex, contentsIndex, questionIndex, questionItem.categoryContentAnswer)} 
                                                                                                        />
                                                                                                    </Grid>
                                                                                                :<Grid item xs={3} sx={{border: '2px solid rgb(218,220,224)', borderTop: '0px', borderRight: '0px', p: 0.5, display: 'flex', justifyContent: 'center'}}></Grid>
                                                                                                }
                                                                                                <>
                                                                                                    <Grid item xs={5} >
                                                                                                        <div className='note-for-answer'>
                                                                                                            <div className='suggest-note' key={`current ${categoryPatientsItem.currentHealthRecordsContent}-${questionIndex} examinationName ${currentHealthRecordExamining.examinationName}`}>                                                                          
                                                                                                                <TextareaAutosize className='textarea-autosize' rows={1} defaultValue={questionItem.categoryContentNote}
                                                                                                                    disabled={!(dataPantientsReadyExamining.status === 1)}
                                                                                                                    onChange={(e) => handleAnswerValueNewMedicalBook(categoryPatientsIndex, contentsIndex, questionIndex, e.target.value)}    
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </>
                                                                                            
                                                                                        </Box>
                                                                                    ))}
                                                                                </Grid>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Collapse>
                                                        </div>
                                                    ))}
                                                    </List>
                                                    {currentHealthRecordExamining.conclusion != null &&
                                                        (
                                                            <>
                                                                <div className='conclusion' style={{marginLeft: '16px', marginTop: '4px'}}>
                                                                <Typography variant='h6' sx={{color: 'blue', fontWeight: 'bolder'}}>{`Kết luận của bác sĩ: ${currentHealthRecordExamining.conclusion}`}</Typography>
                                                                </div>
                                                                <div className='time-examining' style={{marginLeft: '16px', marginTop: '4px', width: '99%'}}>
                                                                <Typography variant='subtitle1' sx={{color: '#009688', fontWeight: 'bolder', fontSize: '1.08rem'}}>
                                                                    {`${currentHealthRecordExamining.doctorFullName}, 
                                                                    ${moment(currentHealthRecordExamining.timeUpdateState != null ? currentHealthRecordExamining.timeUpdateState:currentHealthRecordExamining.timeRegister).format('LLLL')}`}
                                                                    </Typography>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    </>
                                                    )
                                                }
                                                
                                                
                                            </div>
                                        </>
                                        
                                    )}
                                    {currentScreen === ScreenContent.LoadScreen && (
                                        <>
                                            <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                                <CircularProgress />
                                                <Typography variant='subtitle1' sx={{mt: 1}}>Đang tải dữ liệu, hãy chờ một chút...</Typography>
                                            </Box>
                                        </>
                                    )}
                                </div>
                            </Grid>
                        )}
                        

                        
                    </Grid>
                </Box>
            </Container>

            <CompleteExamination openModalCompleteExamining={openModalCompleteExamining} setOpenModalCompleteExamining={setOpenModalCompleteExamining} 
                handleCompleteExaminingForPantient={handleCompleteExaminingForPantient} dataExaminingForConclusion={dataExaminingForConclusion}
            />
            <AlertProcessingBackdrop 
                openAlertProcessingBackdrop={openAlertProcessingBackdrop} changeBackground={true}
            />
            {/* <AlertConfirm openAlertConfirmModal={openAlertConfirmModal.open} onCloseAlertConfirmModal={setOpenAlertConfirmModal} 
                titleConfirm={openAlertConfirmModal.title} setOpenModalCompleteExamining={setOpenModalCompleteExamining} 
            /> */}
        </>
    )
}

export default MainDoctorExamining