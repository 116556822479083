const ScreenContent = {
    ClickPatient: "clickPatient",
    ClickPredecessor: "clickPredecessor",
    ClickListBook: 'clickListBook',
    ClickOneBook: "clickOneBook",
    NoScreen: 'noScreen',
    EditPredecessor:'editPredecssor',
    LoadScreen: 'loadScreen'
  };
  
  export default ScreenContent;