import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getGeneralStatistics } from '../../Service/StatisticsService'; 
import {Button, Container, Box, CircularProgress, Typography } from '@mui/material';

const normalizeMonthlyData = (data, year) => {
  const months = Array.from({ length: 12 }, (_, i) => i + 1); // Tạo danh sách từ 1 -> 12
  const yearlyData = data.filter((item) => item.year === year); // Lọc theo năm
  return months.map((month) => {
    const found = yearlyData.find((item) => item.month === month);
    return found || { year, month, count: 0 }; // Thêm tháng thiếu với count = 0
  });
};

const MonthlyStatsChart = ({ data }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Năm hiện tại

  // Dữ liệu chuẩn hóa theo năm
  const chartData = normalizeMonthlyData(data, currentYear);

  return (
    <Box>
      <h2>Theo từng tháng năm {currentYear}</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" 
          fill='#82ca9d'
          />
        </BarChart>
      </ResponsiveContainer>

      {/* Nút điều hướng năm */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button 
          variant="outlined" 
          onClick={() => setCurrentYear((prev) => prev - 1)} 
          sx={{ mr: 1 }}
        >
          ← Năm {currentYear - 1}
        </Button>
        <Button 
          variant="outlined" 
          onClick={() => setCurrentYear((prev) => prev + 1)} 
          sx={{ ml: 1 }}
        >
          Năm {currentYear + 1} →
        </Button>
      </Box>
    </Box>
  );
};

// Hàm chuẩn hóa dữ liệu đủ 4 quý
const normalizeQuarterlyData = (data, year) => {
  const quarters = [1, 2, 3, 4]; // Danh sách quý
  const yearlyData = data.filter((item) => item.year === year); // Lọc dữ liệu theo năm
  return quarters.map((quarter) => {
    const found = yearlyData.find((item) => item.quarter === quarter);
    return found || { year, quarter, count: 0 }; // Thêm quý thiếu với count = 0
  });
};

const QuarterlyStatsChart = ({ data }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Năm hiện tại

  // Dữ liệu chuẩn hóa theo năm
  const chartData = normalizeQuarterlyData(data, currentYear);

  return (
    <Box>
    <h2>Theo từng quý năm {currentYear}</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="quarter" label={{ value: `Thống kê theo từng quý của năm ${currentYear}`, position: 'insideBottom', offset: 0 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar  dataKey="count" fill='#8884d8' />
        </BarChart>
      </ResponsiveContainer>

      {/* Nút điều hướng năm */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button 
          variant="outlined" 
          onClick={() => setCurrentYear((prev) => prev - 1)} 
          sx={{ mr: 1 }}
        >
          ← Năm {currentYear - 1}
        </Button>
        <Button 
          variant="outlined" 
          onClick={() => setCurrentYear((prev) => prev + 1)} 
          sx={{ ml: 1 }}
        >
          Năm {currentYear + 1} →
        </Button>
      </Box>
    </Box>
  );
};


const StatsTable = ({ data, type }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>{type === 'monthly' ? 'Month' : type === 'quarterly' ? 'Quarter' : 'Year'}</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{type === 'monthly' ? item.month : type === 'quarterly' ? item.quarter : item.year}</td>
            <td>{item.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const TotalStats = ({ totalVisits, reVisits }) => {
    return (
      <div>
        <h5>Tổng số bệnh nhân đã đăng ký khám: <strong>{totalVisits}</strong></h5>
        <h5>Tổng bệnh nhân tái khám: <strong>{reVisits}</strong></h5>
        <h5>Tỉ lệ bệnh nhân tái khám: <strong>{(reVisits / totalVisits * 100).toFixed(2)}%</strong> </h5>
      </div>
    );
};
const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#d084d8', '#84d8d8'];

const YearlyStatsChart = ({ data }) => {
  // Lấy năm hiện tại
  const currentYear = new Date().getFullYear();

  // Tạo danh sách 5 năm gần nhất (năm hiện tại và 4 năm trước đó)
  const years = Array.from({ length: 5 }, (_, index) => currentYear - 4 + index);

  // Tạo đối tượng dữ liệu mặc định cho 5 năm gần nhất
  const yearData = years.map(year => {
    const found = data.find(item => item.year === year);
    return {
      year,
      total: found ? found.count : 0, // Nếu không có dữ liệu thì set count = 0
      success: found ? found.successCount : 0,
      pending: found ? found.pendingCount : 0 
    };
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={yearData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="total"
          fill={COLORS[0]}
          barSize={50}
        />
        <Bar
          dataKey="success"
          fill={COLORS[1]}
          barSize={50}
        />
        <Bar
          dataKey="pending"
          fill={COLORS[2]}
          barSize={50}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
const StatsDashboard = ({ data }) => {
return (
    <Box sx={{mt:5}}>
    <h1>THỐNG KÊ TỔNG QUAN</h1>
    
    <TotalStats totalVisits={data.totalVisits} reVisits={data.reVisits} />
    
    
    <MonthlyStatsChart data={data.monthlyStats} />
    {/* <StatsTable data={data.monthlyStats} type="monthly" /> */}
    
    
    <QuarterlyStatsChart data={data.quarterlyStats} />
    {/* <StatsTable data={data.quarterlyStats} type="quarterly" /> */}
    
    <h2>Trong 5 năm gần đây</h2>
    <YearlyStatsChart data={data.yearlyStats} />
    {/* <StatsTable data={data.yearlyStats} type="yearly" /> */}
    </Box>
);
};

function GeneralStatistics(){
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const fetchStatistics = async () => {
        try {
            const response = await getGeneralStatistics();
            setData(response);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        }
    };
    useEffect(() => {
        fetchStatistics();
    }, []);
    useEffect(() => {
        // console.log(data)
        setIsLoading(data == null)
    },[data])

    return (
        <Container maxWidth="lg" sx={{ mt: 1 }}>
        <Box sx={{ height: 600, width: '100%', mt: 1.2, position: 'relative' }}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        Đang tải dữ liệu, hãy chờ một chút...
                    </Typography>
                </Box>
            ) : StatsDashboard({data})
        }
            </Box>
        </Container>

    );
}


export default GeneralStatistics