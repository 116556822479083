import React, { useState, useContext, useEffect, useRef } from 'react';
//mui theme
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import { Box, Typography, Switch } from '@mui/material';
//api
import { editUserLogin, updateUserAdmin} from '../../Service/UserService';
import { UserContext } from '../../context/UserContext';


function EditUser(props) {
    
    const {setUser, user} = useContext(UserContext);
    const [inputUser, setInputUser] = useState({});
    
    const [loading, setLoading] = useState(false);
    const [userNameDefault, setUserNameDefault] =useState();
    const [defaultPosition, setDefaultPosition] = useState(null);

    const handleCloseModal = (event, reason) => {
        if(reason && reason === "backdropClick"){
            return;
        }
        else{
            props.setOpenModalEditUser(false);
            setTimeout(() => {
                setInputUser(props.user)
            }, 400)
        }
    }
    const listPosition = [
        {id:'1', positionName:'Admin'},
        {id: '2', positionName: 'Bác sĩ'},
        {id: '3', positionName: 'Tiếp nhận'},
      ]
    

    const onChangeUserName = (value) => {
        const _inputUser = {...inputUser};
        _inputUser.userName = value
        setInputUser(_inputUser);
    }
    const onChangeUserFullName = (value) => {
        const _inputUser = {...inputUser};
        _inputUser.userFullName = value
        setInputUser(_inputUser);
    }
    const onChangeEmail = (value) => {
        const _inputUser = {...inputUser};
        _inputUser.userEmail = value
        setInputUser(_inputUser);
    }
    const onChangeActiveUser = (value) => {
        const _inputUser = {...inputUser};
        _inputUser.userIsActive = value.target.checked
        setInputUser(_inputUser);
    }
    
    const onSelectPosition = (e, value) => {
        setInputUser(data => {
          return {
            ...data,
            userPosition: !!value ? value.id : 0,
            positionName: !!value ? value.positionName : null
          }
        })
      }

    const handleValidate = () => {
        let isValid = true;
        if(inputUser.userName === ''){
            toast.warning('Vui lòng nhập tên đăng nhập!', {toastId: 'validateUserName'})
            isValid = false;
        }
        if(inputUser.userFullName === ''){
            toast.warning('Vui lòng nhập họ và tên!', {toastId: 'validateUserFullName'})
            isValid = false;
        }
        if(props.isAdmin && inputUser.userPosition === ''){
            toast.warning('Vui lòng chọn chức vụ!', {toastId: 'validateUserPosition'})
            isValid = false;
        }
        return isValid;
    }

    const handleUpdate = async () => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2 * 100));
        if(handleValidate()){
            // console.log(inputUser)
            // setLoading(false)
            // return;
            let responseUpdate;
            if(props.isAdmin){
                responseUpdate = await updateUserAdmin(inputUser);
            }else{
                responseUpdate = await editUserLogin(inputUser);
            }

            if(responseUpdate.status === 400){
                toast.error(responseUpdate.data, {toastId: 'handleChangeEditUserError2'})
            }
            else if(responseUpdate.status === 200){
                if(!props.isAdmin){
                    if(inputUser.userName !== userNameDefault){
                        localStorage.setItem('jwt', responseUpdate.data.token)
                    }
                    
                    const userEdit= {
                        isAuthenticated: true, 
                        isLogin: true, 
                        userId: user.userId,
                        userName: inputUser.userName, 
                        userFullName: inputUser.userFullName, 
                        positionName: user.positionName,
                        userEmail: inputUser.userEmail
                    }
                    localStorage.setItem('userLogin', JSON.stringify(userEdit))
                    setUser(userEdit);
                }else{
                    props.updateUser(inputUser)
                }
                toast.success(responseUpdate.data, {toastId: 'handleChangeEditUserSuccess'})
                handleCloseModal();
            }
        }
        setLoading(false)
    }
    useEffect(() => {
        if(props.isAdmin === false){
            setInputUser({
                userName: user.userName,
                userEmail: user.userEmail,
                userFullName: user.userFullName
            })
            setUserNameDefault(user.userName)
        }else if(Object.keys(props.user).length > 0){
            let selectUser = {...props.user};
            
            const defaultOption = listPosition.find(item => item.positionName === props.user.positionName);
            if(!!defaultOption){
                selectUser.userPosition = defaultOption.id
            }
            setDefaultPosition(defaultOption || null); 
            setInputUser(selectUser)
        }
    },[props.user, user, props.openModalEditUser])

    return (
        <>
            <Dialog maxWidth={'xs'} fullWidth={true} open={props.openModalEditUser} onClose={(event, reason) => handleCloseModal(event, reason)} disableEscapeKeyDown={true} >
                <DialogTitle sx={{ m: 0, p: 1.4, fontWeight: 'bolder', fontSize: '22px' }}>Chỉnh sửa thông tin</DialogTitle>
                <IconButton onClick={() => handleCloseModal()}sx={{position: 'absolute', right: 5, top: 7}}>
                    <CloseIcon fontSize='medium'/>
                </IconButton>
                
                <DialogContent dividers sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {loading ? 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress />
                        </div>
                    :
                        <>
                            <TextField autoFocus={true} label="Họ và tên" variant="outlined" type={"text"} sx={{ width: '40ch', mb: 2.5 }} 
                                value={inputUser.userFullName}
                                onChange={(e) => onChangeUserFullName(e.target.value)}
                            />

                            <TextField label="Tên đăng nhập" variant="outlined" type={"text"} sx={{ width: '40ch', mb: 2.5 }} 
                                value={inputUser.userName}
                                onChange={(e) => onChangeUserName(e.target.value)} 
                            />
                            <TextField label="Email" variant="outlined" type={"text"} sx={{ width: '40ch', mb: 2.5 }} 
                                value={inputUser.userEmail}
                                onChange={(e) => onChangeEmail(e.target.value)} 
                            />
                            {props.isAdmin && (
                                <Box sx={{display:'flex', alignItems:'center', mb: 2.5}}>
                                <Autocomplete 
                                    options={listPosition} 
                                    getOptionLabel={(option) => option.positionName}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                        {option.positionName}
                                        </li>
                                    )}
                                    value={defaultPosition}
                                    onChange={(e, value) => onSelectPosition(e, value)}
                                    renderInput={(params) => <TextField {...params} label="Vị trí" />} 
                                    sx={{ width: '20.95ch', mr:2.5 }}
                                />
                                <Typography sx={{ marginRight: 1, }}>Trạng thái:</Typography>
                                <Switch 
                                    checked={inputUser.userIsActive ?? false} 
                                    onChange={(e) => onChangeActiveUser(e)} 
                                />
                            </Box>
                            )}
                            
                            <Stack spacing={2} direction="column">
                                <Button sx={{ height: '6vh', width: '30ch' }} variant="contained" color="success" style={{ margin: 'auto' }} onClick={() => handleUpdate()}>Cập nhật thông tin</Button>
                            </Stack>
                        </>
                    }

                </DialogContent>
            </Dialog>
        </>
    )
}

export default EditUser